import {
  GET_CATEGORIES,
  ADD_CART,
  USER_DATA,
  UPDATE_CART,
  SINGLE_BLOG,
  SINGLE_MY_ORDER,
} from "../actions/actions";

const INITIAL_STATE = {
  categories: [],
  loading: false,
  cartAdded: [],
  singleBlog: {},
  singleMyOrder: {},
  userData: {
    emailId: "",
    phoneNumber: "",
    accessToken: "",
    cartData: {},
    defaultAddress: {
      address1: "",
      address2: "",
      city: "",
      company: "",
      country: "",
      countryCodeV2: "",
      firstName: "",
      lastName: "",
      name: "",
      phone: "",
      province: "",
      provinceCode: "",
      zip: "",
    }
  },

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories,
        loading: false,
      };
    }
    case ADD_CART: {
      // let added = [];
      // if (state.cartAdded.includes(action.payload)) {
      //   alert("exit");
      //   added = state.cartAdded;
      // } else {
      //   added = state.cartAdded.concat(action.payload);
      // }
      return {
        ...state,
        cartAdded: action.payload,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case SINGLE_BLOG: {
      return {
        ...state,
        singleBlog: action.payload,
      };
    }
    case SINGLE_MY_ORDER: {
      return {
        ...state,
        singleMyOrder: action.payload,
      };
    }
    // case UPDATE_CART: {
    //   console.log(action.payload, "action.payload");
    //   return {
    //     ...state,
    //     userData: action.payload,
    //   };
    // }
    default:
      return state;
  }
};
